import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import type { Program, Session } from 'src/types/program';
import type { Protocol } from 'src/types/protocols';
import { FeedbackResponse } from 'src/types/feedbackResponse';

interface ProgramsState {
  programs: Program[];
  allProtocols: Protocol[];
  protocols: Protocol[];
  protocolDeployList: Protocol[];
  sessionAssessments: any[];
  isModalOpen: boolean;
  selectedProgram: Program;
  session: Session;
  feedbackResponses: FeedbackResponse[];
  currentProtocol: Protocol;
  totalProtocols: number;
  protocolsFetched: boolean;
  protocolIsEditing: boolean;
}

const initialState: ProgramsState = {
  programs: [],
  totalProtocols: 0,
  protocols: [],
  protocolDeployList: [],
  allProtocols: [],
  sessionAssessments: [],
  isModalOpen: false,
  selectedProgram: undefined,
  session: undefined,
  feedbackResponses: [],
  currentProtocol: undefined,
  protocolsFetched: false,
  protocolIsEditing: false,
};

const slice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    getFeedbackResponses(
      state: ProgramsState,
      action: PayloadAction<{ feedbackResponses: FeedbackResponse[] }>
    ) {
      const { feedbackResponses } = action.payload;
      state.feedbackResponses = feedbackResponses;
    },
    createProgram(state: ProgramsState, action: PayloadAction<{}>) {
      state.isModalOpen = false;
    },
    getSession(state: ProgramsState, action: PayloadAction<{ session: Session }>) {
      const { session } = action.payload;
      state.session = session;
    },
    openModal(state: ProgramsState) {
      state.isModalOpen = true;
    },
    closeModal(state: ProgramsState) {
      state.isModalOpen = false;
    },
    setSelectedProgram(state: ProgramsState, action: PayloadAction<Program>) {
      state.selectedProgram = action.payload;
    },
    getProtocols(
      state: ProgramsState,
      action: PayloadAction<{ protocols: Protocol[]; totalProtocols: number }>
    ) {
      const { protocols, totalProtocols } = action.payload;
      state.protocols = protocols;
      state.totalProtocols = totalProtocols;
      state.protocolsFetched = true;
    },
    getProtocolDeployList(
      state: ProgramsState,
      action: PayloadAction<{ protocols: Protocol[]; totalProtocols: number }>
    ) {
      const { protocols, totalProtocols } = action.payload;
      state.protocolDeployList = protocols;
      state.totalProtocols = totalProtocols;
    },
    getProtocolsAllTypes(state: ProgramsState, action: PayloadAction<{ protocols: Protocol[] }>) {
      const { protocols } = action.payload;
      state.allProtocols = protocols;
    },
    getSessionAssessments(state: ProgramsState, action: PayloadAction<{ response: any[] }>) {
      const { response } = action.payload;
      state.sessionAssessments = response;
    },
    getCurrentProtocol(state: ProgramsState, action: PayloadAction<{ protocol: Protocol }>) {
      const { protocol } = action.payload;
      state.currentProtocol = protocol;
    },
    resetProtocolsFetched(state: ProgramsState) {
      state.protocolsFetched = false;
    },
    setProtocolsFetched(state: ProgramsState) {
      state.protocolsFetched = true;
    },
    setProtocolisEditing(
      state: ProgramsState,
      action: PayloadAction<{ protocolIsEditing: boolean }>
    ) {
      const { protocolIsEditing } = action.payload;
      state.protocolIsEditing = protocolIsEditing;
    },
  },
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const createProgram = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.createProgram({}));
};

export const getProtocols =
  (
    startFrom?: number,
    type?: string,
    searchTerm?: string,
    limit?: number,
    sortKey?: string,
    sortOrder?: string
  ): AppThunk =>
  async (dispatch) => {
    const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
    try {
      const params = {
        startFrom: startFrom * (limit ? limit : 10) || 0,
        limit: limit || 10,
        type: type || 'Custom',
      };

      if (searchTerm && searchTerm.length > 0) {
        params['searchTerm'] = searchTerm;
      }

      if (sortKey && sortOrder) {
        params['sortKey'] = sortKey;
        params['sortOrder'] = sortOrder;
      }

      const response = await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/protocols`,
        params: params,
      });
      dispatch(
        slice.actions.getProtocols({
          protocols: response.data.protocols,
          totalProtocols: response.data.totalProtocols,
        })
      );
    } catch (error) {
      console.error('Redux ERROR:', error);
      dispatch(slice.actions.getProtocols({ protocols: [], totalProtocols: 0 }));
    }
  };

export const getProtocolDeployList =
  (
    startFrom?: number,
    type?: string,
    searchTerm?: string,
    limit?: number,
    deviceType?: string,
    placementSystem?: string
  ): AppThunk =>
  async (dispatch) => {
    const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];

    try {
      const params = {
        startFrom: startFrom * (limit ? limit : 10) || 0,
        limit: limit || 10,
        type: type || 'Custom',
      };

      if (searchTerm && searchTerm.length > 0) {
        params['searchTerm'] = searchTerm;
      }

      if (deviceType) {
        params['deviceType'] = deviceType;
      }

      if (placementSystem) {
        params['placementSystem'] = placementSystem;
      }

      const response = await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/protocols`,
        params: params,
      });

      dispatch(
        slice.actions.getProtocolDeployList({
          protocols: response.data.protocols,
          totalProtocols: response.data.totalProtocols,
        })
      );
    } catch (error) {
      console.error('Redux ERROR:', error);
      dispatch(slice.actions.getProtocolDeployList({ protocols: [], totalProtocols: 0 }));
    }
  };

export const resetProtocolsFetched = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.resetProtocolsFetched());
};

export const setProtocolsFetched = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setProtocolsFetched());
};

export const getProtocolsAllTypes = (): AppThunk => async (dispatch) => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  try {
    const response = await Promise.all([
      await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/protocols?type=Custom`,
      }),
      await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/protocols?type=NMI`,
      }),
      await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/protocols?type=True Bearing`,
      }),
    ]);
    const allProtocols = [
      ...response[0].data.protocols,
      ...response[1].data.protocols,
      ...response[2].data.protocols,
    ];
    dispatch(slice.actions.getProtocolsAllTypes({ protocols: allProtocols }));
  } catch (error) {
    console.error('Redux ERROR:', error);
  }
};

export const getCurrentProtocol =
  (protocolId: string): AppThunk =>
  async (dispatch) => {
    const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];

    const response = await axios({
      method: 'get',
      url: `v2/therapist/${therapistId}/protocol/${protocolId}`,
    });

    dispatch(slice.actions.getCurrentProtocol({ protocol: response.data }));
  };

export const setProtocolisEditing =
  (protocolIsEditing: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setProtocolisEditing({ protocolIsEditing }));
  };

export default slice;
